.backgroundGradient {
  background-color: #efece6;
  /* background-color: hsla(0, 0%, 100%, 1);
  background-image: radial-gradient(
      at 40% 20%,
      hsla(12, 100%, 89%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 80% 0%, hsla(189, 100%, 92%, 1) 0px, transparent 80%),
    radial-gradient(at 0% 50%, hsla(355, 79%, 96%, 1) 0px, transparent 80%),
    radial-gradient(at 80% 50%, hsla(340, 100%, 92%, 1) 0px, transparent 80%),
    radial-gradient(at 0% 100%, hsla(4, 100%, 94%, 1) 0px, transparent 80%),
    radial-gradient(at 80% 100%, hsla(240, 100%, 83%, 1) 0px, transparent 80%),
    radial-gradient(at 0% 0%, hsla(340, 100%, 81%, 1) 0px, transparent 80%); */
}
